<template>
  <!-- loading START -->
  <transition name="dialog-fade" mode="out-in">
    <div class="loading loading-default" v-if="loadingVisible">
      <div class="loading-default-container">

        <!-- spinner START -->
        <div class="loader-container">
          <div class="loader loader-one"></div>
          <div class="loader loader-two"></div>
        </div>
        <!-- spinner END -->

        <h4 v-html="headline"></h4>
        <div class="excerpt" v-html="excerpt"></div>

        <!-- cart:actions:checkout:abort START -->
        <div
          class="abort"
          v-on:click="abort()"
          v-if="showRestoreCart"
        >
          <span v-html="$t('terminal.cart.checkout.abort.headline')"></span>
        </div>
        <!-- cart:actions:checkout:abort END -->
      </div>
    </div>
  </transition>
  <!-- loading END -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'loading-default',
  props: {
    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    showRestoreCart: {
      type: Boolean,
    },

    abort: {
      type: Function,
    },
  },
  methods: {
    ...mapActions([
      'clearCart',
    ]),

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    resetStoreCart() {
      // reset store cart
      this.clearCart();
      // redirect to home
      // this.$router.push('/');
      // redirect to home + reload app
      window.location.assign('/');
    },
  },
};
</script>
